.header {
  padding: 30px 0;
  box-shadow: 0px 8px 8px -4px rgba(112, 127, 209, 0.08);
}

.header-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-menu ul li {
  margin: 0 36px;
}

.header-menu ul li:first-child {
  margin-left: 0;
}

.header-menu ul li:last-child {
  margin-right: 0;
}

.header-menu ul li a {
  color: $base-font-color;
  font-size: 15px;
}

.header-menu ul li a:hover {
  color: $base-link-color;
}

.header-menu ul li.active a {
  font-weight: bold;
  color: $base-link-color;
}

.header-profile {
  display: flex;
  align-items: center;
}

.profile-img, .header-msg {
  position: relative;
  flex-shrink: 0;
}

.header-msg {
  cursor: pointer;
}

.profile-img {
  margin-left: 20px;
}

.profile-img img {
  border-radius: 50%;
}

.info-point {
  background: $danger-color;
  border: 1px solid #fff;
  position: absolute;
  width: 10px;
  height: 10px;
  right: 0;
  top: 0;
  border-radius: 50%;
}

.open-mobile-menu {
  width: 30px;
  height: 23px;
  position: relative;
  border: 0;
  background: transparent;
}

.open-mobile-menu span {
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: $base-link-color;
  border-radius: 5px;
  left: 0;
  transition: all .3s;
}

.open-mobile-menu span:first-child {
  top: 0;
}

.open-mobile-menu span:nth-child(2) {
  top: 50%;
  margin-top: -1.5px;
}

.open-mobile-menu span:last-child {
  bottom: 0;
}

.open-mobile-menu.open span:first-child {
  transform: rotate(45deg);
  top: 50%;
  margin-top: -1.5px;
}

.open-mobile-menu.open span:nth-child(2) {
  opacity: 0;
}

.open-mobile-menu.open span:last-child {
  transform: rotate(-45deg);
  bottom: 50%;
  margin-bottom: -1.5px;
}

.mobile-menu-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: none;
}

.mobile-menu-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
}

.mobile-menu-container {
  position: absolute;
  top: 0;
  left: -500px;
  max-width: 360px;
  width: 100%;
  height: 100%;
  background-color: $base-link-color;
  padding: 30px 22px;
}

.mobile-menu-close {
  width: 30px;
  height: 23px;
  position: relative;
  border: 0;
  background: transparent;
}

.mobile-menu-close span {
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #fff;
  border-radius: 5px;
  left: 0;
  transition: all .3s;
}

.mobile-menu-close span:first-child {
  transform: rotate(45deg);
  top: 50%;
  margin-top: -1.5px;
}

.mobile-menu-close span:last-child {
  transform: rotate(-45deg);
  bottom: 50%;
  margin-bottom: -1.5px;
}

.mobile-menu-content {
  margin-top: 40px;
}

.mobile-menu-content ul {
  margin: 40px 0 0 0;
  padding: 0;
  list-style: none;
}

.mobile-menu-content ul li {
  margin-bottom: 15px;
}

.mobile-menu-content ul li a {
  color: #fff;
  font-size: 13px;
  line-height: 16px;
}

#profile-block {
  cursor: pointer;
}


.tooltip-content {
  display: none;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px -4px rgb(112 127 209 / 8%), -10px 16px 24px rgb(112 127 209 / 8%);
  border-radius: 10px;
  padding: 20px;
  list-style: none;
  margin: 0;
  border: 0;
  position: absolute;
  min-width: 225px;
  opacity: 0;
  z-index: 1000;
}

//.tooltipster-content .tooltip-content {
//  display: block;
//}
//
//.tooltipster-sidetip .tooltipster-box {
//  background: #FFFFFF;
//  box-shadow: 0px 4px 8px -4px rgb(112 127 209 / 8%), -10px 16px 24px rgb(112 127 209 / 8%);
//  border-radius: 10px;
//  padding: 20px;
//  list-style: none;
//  margin: 0;
//  border: 0;
//}
//.tooltipster-base {
//  pointer-events: auto;
//}
.tooltip-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tooltip-content ul li {
  position: relative;
}

.tooltip-content ul li span {
  background: $danger-color;
  border: 1px solid #fff;
  position: absolute;
  width: 8px;
  height: 8px;
  right: 0;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
}

.tooltip-content ul li:first-child a {
  padding-top: 0;
}

.tooltip-content ul li a {
  font-size: 15px;
  line-height: 20px;
  color: $base-font-color;
  padding: 8px 0;
  display: block;
}

.tooltip-content ul li a:hover {
  color: $danger-color
}

.tooltip-content ul li.active a {
  color: $danger-color
}

.tooltip-content ul li {
  //margin-bottom: 15px;
  position: relative;
}

.tooltip-content ul li:last-child a {
  //margin-bottom: 0px;
  //padding-bottom: 0;
}

.tooltip-content ul li.logout {
  border-top: 1px solid #D9DCEB;
  padding-top: 5px;
  margin-top: 8px;
}

.tooltip-content ul li.logout a {
}

.header-login {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: $base-link-color;
  display: flex;
  align-items: center;
}

.header-login:hover {
  color: #49569D;
}

.header-login svg {
  margin-left: 20px;
}

.header-login svg path {
  fill: $base-link-color;
}

.header-login:hover svg path {
  fill: #49569D;
}


@include media-breakpoint-down(lg) {
  .mobile-menu-block {
    display: block;
  }
  .header {
    padding: 20px 0;
  }
}