.politika-signature {
  text-align: right;
  margin: 40px 0;
}
.politika-title {
  color: $danger-color;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 20px;
}
h6.politika-section {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin: 30px 0 20px 0;
}
.politika-content p {
  margin-bottom: 20px;
}

.politika-subtitle {
  text-align: center;
}
@include media-breakpoint-down(lg) {
  .politika-signature {
    font-size: 15px;
    line-height: 20px;
    margin: 30px 0;
  }

  .politika-title {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .politika-subtitle {
    font-size: 13px;
    line-height: 16px;
  }
  h6.politika-section {
    font-size: 15px;
    line-height: 24px;
    margin: 20px 0 10px 0;
  }

  .politika-content p {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 10px;
  }
}