.client-card {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
}
.client-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.client-name {
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  flex-grow: 1;
}
.client-meta img {
  margin-right: 24px;
  border-radius: 50%;
  overflow: hidden;
  width: 41px;
  height: 41px;
  flex-shrink: 0;
}
.client-meta a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}
.client-history-date {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
  color: $base-gray-color;
  display: flex;
  align-items: start;
}
.client-history-date span {
  flex-shrink: 0;
  width: 90px;
  margin-right: 20px;
}
.client-history-result {

  display: flex;
  align-items: start;
}
.client-history-result span {
  flex-shrink: 0;
  width: 90px;
  margin-right: 20px;

}
.client-history-item {
  margin-top: 24px;
}
.client-history:first-child {
  margin-top: 0;
}
.client-history .client-history-item:last-child {
  margin-bottom: 0;
}
.open-all-history {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $base-gray-color;
  margin-top: 30px;
  font-size: 15px;
  line-height: 20px;
}
.open-all-history:hover {
  color: $base-gray-color;
}
.open-all-history svg {
  margin-left: 12px;
  transition: all 0.3s;
}
.client-history-hided {
  display: none;
}
.open-all-history.open svg {
  transform: rotate(180deg);
}


.therapist-start-title {
  text-align: center;
  margin: 50px 0 70px;
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;
  color: $base-link-color;
}

.therapist-start-text {
  font-size: 18px;
  line-height: 24px;
}
.therapist-start-text p {
  margin-bottom: 20px;
}
.therapist-start-text p:last-child {
  margin-bottom: 0px;
}

.dots {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.dots span {
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: $danger-color;
  margin-right: 10px;
}

.therapist-start-row img {
  border-radius: 4px;
}

.therapist-start-row {
  align-items: center;
  margin-bottom: 120px;
}

.therapist-start-result {
  margin-left: 100px;
}
.therapist-start-result-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 30px;
}
.therapist-start-field {
  background: #FFFFFF;
  border: 1px solid #D9DCEB;
  padding: 20px;
  border-radius: 10px;
  font-size: 15px;
  line-height: 20px;
}
.therapist-start-field ol {
  margin: 20px 0 0;
  padding: 0 0 0 20px;
}
.therapist-start-field ol li span {
  height: 17px;
  width: 1px;
  background-color: $base-gray-color;
  border-radius: 50px;
  display: block;
}

.therapist-start-records {
  display: flex;
}
.therapist-start-records .record-card {
  margin-right: 20px;
  width: 250px;

}

.therapist-page-start {
  overflow: hidden;
}

.records-empty {
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  margin-top: 110px;
  margin-bottom: 100px;
  text-align: center;
  color: $base-gray-color;
}
@include media-breakpoint-down(lg) {

  .records-empty {
    font-size: 17px;
    line-height: 24px;
    margin-top: 70px;
    margin-bottom: 50px;
  }
  .therapist-start-result {
    margin: 15px 0 0 0;
  }
  .therapist-start-row {
    margin-bottom: 80px;
  }

  .therapist-start-records {
    overflow: auto;
  }

  .therapist-start-title {
    font-size: 23px;
    line-height: 35px;
    margin: 20px 0 40px;
  }

  .therapist-start-text {
    font-size: 15px;
    margin-bottom: 30px;
  }

  .therapist-start-text p {
    margin-bottom: 10px;
  }

  .dots {
    margin-bottom: 20px;
  }

  .client-history-item {
    margin-top: 20px;
  }
  .client-history-result span {
    width: 100%;
    margin-bottom: 9px;
    font-weight: 600;
  }
  .client-history-result {
    font-size: 15px;
    line-height: 20px;
    flex-wrap: wrap;
  }
  .client-name {
    width: 100%;
    order: 10;
    font-size: 15px;
    line-height: 22px;
    margin-top: 15px;
  }
  .client-meta {
    flex-wrap: wrap;
  }
}