.footer {
  padding: 40px 0;
  background-color: $base-link-color;
  flex-shrink: 0;
}
.footer-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-menu li {
  margin-bottom: 15px;
  line-height: 16px;
}

.footer-menu li a {
  color: #fff;
  font-size: 13px;
}
.footer-menu li a:hover {
  text-decoration: underline;
}
.footer-soc {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.footer-soc li {
  margin: 0 10px;
}
.footer-soc li:first-child {
  margin-left: 0;
}
.footer-soc li:last-child {
  margin-right: 0;
}

.copyright {
  font-size: 13px;
  line-height: 16px;
  color: #fff;
}
.footer-cards {
  padding: 0;
  margin: 20px 0 0;
  list-style: none;
  display: flex;
  align-items: center;
}
.footer-cards li {
  margin-right: 10px;
}
.footer-cards li:last-child {
  margin-right: 0;
}