.faq-section-title {
  font-weight: 500;
  font-size: 23px;
  line-height: 29px;
  margin-bottom: 6px;
  color: $base-link-color;
}

.faq-item-content {
  display: none;
}
.faq-item {
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 24px;
}
.faq-item-title {
  padding: 30px;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  cursor: pointer;
}
.faq-item-title:hover,
.faq-item.open .faq-item-title {
  color: $danger-color;
}
.faq-item-content {
  padding: 0 30px 30px;
}
.faq-section {
  margin-bottom: 60px;
}
.vozvrat-title {
  font-weight: 500;
  font-size: 23px;
  line-height: 29px;
  margin-bottom: 40px;
  margin-top: 50px;
}
.faq-item-content a {
  color: $danger-color;
}
.faq-item-content a:hover {
  color: $danger-focus-color;
}
.contacts-data dd {
  margin-bottom: 0;
}
.contacts-data * {
  padding: 15px 0;
  border-bottom: 1px solid #D9DCEB;
}
.cards-cvv {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
  display: flex;
  align-items: center;
}
.cards-cvv li {
  margin-right: 40px;
}
.cards-cvv li:last-child {
  margin-right: 0px;
}

@include media-breakpoint-down(lg) {

  .contacts-data * {
    padding: 0;
    border-bottom: 0;
  }
  .contacts-data dd {
    margin-bottom: 15px;
  }
  .vozvrat-title {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 30px;
    margin-top: 35px;
  }
  .faq-section {
    margin-bottom: 30px;
  }
  .faq-item-content {
    padding: 0 15px 15px;
  }
  .faq-item-title {
    padding: 15px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .faq-section-title {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 0px;
  }
  .faq-item {
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 20px;
  }

}