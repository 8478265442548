.danger-title {
  color: $danger-color;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 50px;
}
.contact-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.contact-list li {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
.contact-list li:last-child {
  margin-bottom: 0;
}
.contact-list li span {
  width: 45px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.contact-text {
  color: #5A5A5A;
  line-height: 20px;
  margin-top: 70px;
}
.contact-text p {
  margin-bottom: 25px;
}

.contact-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 30px;
}

.yandex-map {
  margin-top: 100px;
}

.reviews-add-success {
  margin: 190px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.reviews-add-success span {
  font-weight: 600;
  font-size: 27px;
  line-height: 40px;
  color: $danger-color;
  margin-bottom: 25px;
  display: block;
}
.popup-success {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #fff;
}
#success-message {
  width: 100%;
  max-width: 550px;
}
.popup-success-text {
  text-align: center;
}
.popup-success-text span {
  font-weight: 600;
  font-size: 27px;
  line-height: 40px;
  color: $danger-color;
  margin-bottom: 25px;
  display: block;

}

.fancybox__backdrop {
  background-color: rgba(49, 49, 49, 0.3) !important;
}

#success-message.fancybox__content {
  padding: 0;
  background-color: transparent;
}

.fancybox__container .is-close:focus {
    outline: none!important;
}

@include media-breakpoint-down(lg) {
  .reviews-add-success span,
  .popup-success-text span {
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    margin-bottom: 20px;
  }
  .reviews-add-success {
    margin: 60px 0 110px;
  }
  .yandex-map {
    margin-top: 80px;
  }
  .contact-title {
    font-size: 18px;
    line-height: 24px;
    margin-top: 50px;
    text-align: center;
    margin-bottom: 20px;
  }
  .contact-text {
    margin-top: 60px;
    font-size: 15px;
    line-height: 24px;
  }
  .contact-text p {
    margin-bottom: 24px;
  }
  .contact-list li {
    margin-bottom: 30px;
  }
  .danger-title {
    margin-top: 10px;
    margin-bottom: 45px;
  }
}